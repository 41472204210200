<template>
<div class="flex flex-col justify-center items-start w-full relative">
  <a-form
  ref="form"
  name="infoPersonal"
  class="w-full"
  :rules="rules"
  layout="vertical"
  labelAlign="left"
  :model="InfoFormData"
  @finish="handleSubmit"
  scrollToFirstError="true"
  >
    <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" label="Nome completo" name="nome_completo" class="w-full">
      <a-input v-model:value="InfoFormData.nome_completo"/>
    </a-form-item>
    <div class="flex justify-around gap-5">
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="data_de_nascimento" label="Data de nascimento" class="w-2/5">
        <input type="date" name="data_de_nascimento" v-model="InfoFormData.data_de_nascimento" class="ant-input css-dev-only-do-not-override-19iuou">
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="naturalidade" label="Naturalidade" class="w-3/5">
        <a-input v-model:value="InfoFormData.naturalidade"/>
      </a-form-item>
    </div>
    <div class="flex justify-between gap-5">
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="rg" label="RG" class="w-full">
        <a-input type="number" :controls="false"  v-model:value="InfoFormData.rg" class="w-full"/>
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="orgao_emissor" label="Órgão emissor" class="w-full">
        <a-input v-model:value="InfoFormData.orgao_emissor" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="data_de_expedicao" label="Data de Expedição" class="w-full">
        <input type="date" name="data_de_expedicao" v-model="InfoFormData.data_de_expedicao" class="ant-input css-dev-only-do-not-override-19iuou">
      </a-form-item>
    </div>
    <div class="flex justify-between gap-5">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="rgFile" label="RG (frente e verso) Somente será aceito a Carteira de Identidade*">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              v-model:value="InfoFormData.rgFile"
              :before-upload="(info) => beforeUpload(info, 'rgFile', 'rg')"
              :max-count="1"
              @remove="removeFile('rgFile')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item name="cnh_foto" label="CNH - obrigatório para quem será condutor de veículo em sua função">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'cnh', 'cnh')"
              :max-count="1"
              @remove="removeFileFromArr('cnh', 'cnh')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="nacionalidade" label="Nacionalidade" class="w-full">
      <a-input v-model:value="InfoFormData.nacionalidade" />
    </a-form-item>
    <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="estado_civil" label="Estado civil" class="w-full">
      <a-input v-model:value="InfoFormData.estado_civil" />
    </a-form-item>
    <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="nome_da_mae" label="Nome da mãe (completo*)" class="w-full">
      <a-input v-model:value="InfoFormData.nome_da_mae" />
    </a-form-item>
    <a-form-item name="nome_do_pai" label="Nome do pai" class="w-full">
      <a-input v-model:value="InfoFormData.nome_do_pai" />
    </a-form-item>

    <div class="flex flex-row justify-between gap-4 w-full max-[1000px]:flex-col">
      <div class="w-1/2 flex flex-col text-left justify-end max-[1000px]:w-full">
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="fotoFile" label="Foto 3x4">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              v-model:value="fotoFile"
              :before-upload="(info) => beforeUpload(info, 'fotoFile', 'foto3x4')"
              @remove="removeFile('fotoFile')"
              :max-count="1"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>

      <div class="w-1/2 flex flex-col text-left justify-center items-center max-[1000px]:w-full">
        <a-radio-group v-model:value="gender">
          <a-radio-button value="M">Homem</a-radio-button>
          <a-radio-button value="F">Mulher</a-radio-button>
        </a-radio-group>
        <a-form-item v-if="gender === 'M'" :rules="[{ required: gender === 'M', message: 'Campo Obrigatório' }]" name="reservistaFile" label="Carteira de reservista" class="max-[1000px]:w-full">
          <div class="w-full max-[500px]:w-full mx-auto max-[1000px]:w-full">
            <a-upload-dragger
              v-model:value="reservistaFile"
              :before-upload="(info) => beforeUpload(info, 'reservistaFile', 'reservista')"
              :max-count="1"
              @remove="removeFile('reservistaFile')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <div class="flex justify-around gap-5 max-[1000px]:flex-col">
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="endereço" label="Endereço" class="w-2/3 max-[1000px]:w-full">
        <a-input v-model:value="InfoFormData.endereço" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="bairro" label="Bairro" class="w-1/3 max-[1000px]:w-full">
        <a-input v-model:value="InfoFormData.bairro" />
      </a-form-item>
    </div>
    <div class="flex justify-between gap-5 flex-col">
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="cidade" label="Cidade" class="w-1/3 max-[1000px]:w-full">
        <a-input v-model:value="InfoFormData.cidade" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="estado" label="Estado" class="w-1/3 max-[1000px]:w-full">
        <a-input v-model:value="InfoFormData.estado" />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="cep" label="CEP" class="max-[1000px]:w-full" >
        <a-input type="number" v-model:value="InfoFormData.cep" class="w-full"/>
      </a-form-item>
      <div class="w-1/2 flex flex-col text-left justify-end max-[1000px]:w-full">
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="comprovanteEnderecoFile" label="Comprovante de endereço (contendo CEP)" class="max-[1000px]:w-full">
          <div class="w-2/5 max-[500px]:w-full mx-auto max-[1000px]:w-full">
            <a-upload-dragger
              v-model:value="comprovanteEnderecoFile"
              :before-upload="(info) => beforeUpload(info, 'comprovanteEnderecoFile', 'endereço')"
              :max-count="1"
              @remove="removeFile('comprovanteEnderecoFile')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <div class="flex justify-between gap-5">
      <a-form-item name="telefone_residencial" label="Telefone Fixo" class="w-full">
        <a-input
          type="text"
          maxlength="15"
          @input="formatterResidentialTelephone"
          :value="InfoFormData.telefone_residencial" 
          class="w-full" 
        />
      </a-form-item>
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="telefone_celular" label="Telefone Celular" class="w-full">
        <a-input          
          type="text"
          maxlength="15"
          @input="formatterCelphone"
          :value="InfoFormData.telefone_celular" 
          class="w-full"
        />
      </a-form-item>
    </div>
    <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="email" label="Email" class="w-full">
      <a-input v-model:value="InfoFormData.email" />
    </a-form-item>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <div class="flex flex-wrap justify-normal w-full gap-5">
      <div class="w-[45%]">
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="cpf" label="CPF/CIC" class="w-full">
          <a-input
            type="text" 
            maxlegth="15"
            @input="formatterCpfNumber"
            :value="InfoFormData.cpf" 
            class="w-full"
          />
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="cpfFile" label="CPF/CIC arquivo" class="w-full">
          <div class="w-full flex flex-col text-left justify-end pt-2">
            <div class="w-1/2 max-[500px]:w-full mx-auto">
              <a-upload-dragger
                v-model:value="cpfFile"
                :before-upload="(info) => beforeUpload(info, 'cpfFile', 'cpf-cic')"
                :max-count="1"
                @remove="removeFile('cpfFile')"
                list-type="picture-card"
              >
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">PNG ou PDF</div>
                </div>
              </a-upload-dragger>
            </div>
          </div>
        </a-form-item>
      </div>
      <div class="w-[45%]">
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="pis" label="PIS/PASEP" class="w-full">
          <a-input type="number" :controls="false" v-model:value="InfoFormData.pis" class="w-full"/>
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="pisFile" label="PIS/PASEP arquivo" class="w-full">
          <div class="w-full flex flex-col text-left justify-end pt-2">
            <div class="w-1/2 max-[500px]:w-full mx-auto">
              <a-upload-dragger
                v-model:value="pisFile"
                :before-upload="(info) => beforeUpload(info, 'pisFile', 'pis-pasep')"
                :max-count="1"
                @remove="removeFile('pisFile')"
                list-type="picture-card"
              >
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">PNG ou PDF</div>
                </div>
              </a-upload-dragger>
            </div>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="flex justify-between gap-5">     
    </div>
    <div class="flex justify-between gap-5 max-[1000px]:flex-col">
      <div class="w-full">
        <a-form-item name="ctps" label="CTPS" class="w-full">
          <a-input type="number" v-model:value="InfoFormData.ctps" class="w-full"/>
        </a-form-item>
        <a-form-item name="ctps" label="CTPS arquivo" class="w-full">
          <div class="w-1/2 max-[500px]:w-full mx-auto pt-2">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'ctps', 'ctps')"
              :max-count="1"
              @remove="removeFileFromArr('pisFile')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <a-form-item name="serie" label="Série" class="w-full">
        <a-input v-model:value="InfoFormData.serie" />
      </a-form-item>
      <a-form-item name="estado_ctps" label="Estado" class="w-full">
        <a-input v-model:value="InfoFormData.estadoctps" class="w-full"/>
      </a-form-item>
    </div>
    <div>
      <div class="flex justify-between gap-5 max-[1000px]:flex-col ">
        <div class="w-full">
          <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="tituloEleitor" label="Titulo de eleitor" class="w-full">
            <a-input type="number" v-model:value="InfoFormData.tituloEleitor" class="w-full"/>
          </a-form-item>
          <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="tituloEleitorFile" label="Titulo de eleitor arquivo" class="w-full">
            <div class="w-1/2 max-[500px]:w-full mx-auto pt-2">
              <a-upload-dragger
                v-model:value="tituloEleitorFile"
                :before-upload="(info) => beforeUpload(info, 'tituloEleitorFile', 'titulo-eleitor')"
                :max-count="1"
                @remove="removeFile('tituloEleitorFile')"
                list-type="picture-card"
              >
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else>
                  <loading-outlined v-if="loading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">PNG ou PDF</div>
                </div>
              </a-upload-dragger>
            </div>
          </a-form-item>
        </div>
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="zona" label="Zona" class="w-full">
          <a-input type="number" v-model:value="InfoFormData.zona" />
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="secao" label="Seção" class="w-full">
          <a-input type="number" v-model:value="InfoFormData.secao" class="w-full"/>
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="uf" label="UF" class="w-full">
          <a-input v-model:value="InfoFormData.uf" class="w-full"/>
        </a-form-item>
      </div>
    </div>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <div class="flex flex-row justify-between gap-4 w-full max-[1000px]:flex-col">
      <div class="w-1/2 flex flex-col text-left justify-end max-[1000px]:w-full">
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="numeroSusFile" label="Numero do SUS (CNS)">
          <a class="text-age-blue" target="_blank" href="https://cnesadm.datasus.gov.br/cnesadm/publico/usuarios/cadastro" >Site para consultar</a>
          <div class="w-full mx-auto">
            <a-upload-dragger
              v-model:value="numeroSusFile"
              :before-upload="(info) => beforeUpload(info, 'numeroSusFile', 'numero-sus')"
              :max-count="1"
              @remove="removeFile('numeroSusFile')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left max-[1000px]:w-full">
        <a-form-item name="CID" label="Possui alguma deficiência?" class="flex items-center justify-center w-full">
          <a-radio-group  v-model:value="InfoFormData.pne" button-style="solid" class="flex items-center justify-center">
            <a-radio-button class="border-2" value=true >Sim</a-radio-button>
            <a-radio-button class="border-2" value=false >Não</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item name="PNE_CID" label="Se sim, qual o tipo/CID" class="w-full">
          <a-input v-model:value="InfoFormData.pne_cid"/>
        </a-form-item>
      </div>
    </div>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <div class="flex justify-between gap-5">
      <a-form-item name="ex_funcionario" label="Você já trabalhou na Age Fibra?" class="w-full flex items-center justify-center">
        <a-radio-group  v-model:value="InfoFormData.ex_funcionario" button-style="solid" class="flex items-center justify-center">
          <a-radio-button class="border-2" value=true >Sim</a-radio-button>
          <a-radio-button class="border-2" value=false >Não</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="parente_funcionario" label="Possui parente ou amigo que trabalha ou está fazendo processo seletivo na Age Fibra?" class="w-full flex items-center justify-center">
        <a-radio-group  v-model:value="InfoFormData.parente_funcionario" button-style="solid" class="flex items-center justify-center">
          <a-radio-button class="border-2" value=true >Sim</a-radio-button>
          <a-radio-button class="border-2" value=false >Não</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </div>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <div class="flex justify-between gap-5 flex-wrap">
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="escolaridade" label="Escolaridade" class="w-full">
        <a-input v-model:value="InfoFormData.escolaridade" class="w-full"/>
      </a-form-item>
      <div class="w-1/2 flex flex-col text-left justify-end max-[1000px]:w-full">
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="comprovanteEscolaridadeFile" label="Comprovante de Escolaridade">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              v-model:value="comprovanteEscolaridadeFile"
              :before-upload="(info) => beforeUpload(info, 'comprovanteEscolaridadeFile', 'comprovante-escolaridade')"
              :max-count="1"
              @remove="removeFile('comprovanteEscolaridadeFile')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="curso_de_formacao" label="Curso de Formação" class="w-full">
        <a-input v-model:value="InfoFormData.curso_de_formacao" class="w-full"/>
      </a-form-item>
    </div>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <div class="flex flex-col gap-2">
      <span>Dados bancários</span>
      <div class="flex justify-evenly gap-5 max-[500px]:flex-col ">
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="tipoBanco" label="Tipo">
          <a-input v-model:value="InfoFormData.tipoBanco" class="w-full"/>
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="agenciaBanco" label="Agência">
          <a-input type="number" v-model:value="InfoFormData.agenciaBanco" class="w-full"/> 
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="contaBanco" label="Conta">
          <a-input type="number" v-model:value="InfoFormData.contaBanco" class="w-full"/>
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="digitoBanco" label="Digito">
          <a-input type="number" v-model:value="InfoFormData.digitoBanco" class="w-full"/>
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: 'Campo Obrigatório' }]" name="pix" label="Chave Pix">
          <a-input v-model:value="InfoFormData.pix" class="w-full"/>
        </a-form-item>
      </div>
    </div>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <a-form-item name="tamanho_uniforme" label="Tamanho do uniforme">
      <div class="flex justify-between gap-5 max-[500px]:flex-col ">
        <a-form-item name="altura" label="Altura">
          <a-input v-model:value="InfoFormData.tamanhosUniforme.altura" class="w-full"/>
        </a-form-item>
        <a-form-item name="blusa" label="Blusa">
          <a-input v-model:value="InfoFormData.tamanhosUniforme.blusa" class="w-full"/>
        </a-form-item>
        <a-form-item name="calca" label="Calça">
          <a-input v-model:value="InfoFormData.tamanhosUniforme.calca" class="w-full"/>
        </a-form-item>
        <a-form-item name="bota" label="Bota">
          <a-input v-model:value="InfoFormData.tamanhosUniforme.bota" class="w-full"/>
        </a-form-item>
      </div>
    </a-form-item>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item name="estado_civil" label="Certidão de Estado civil (caso solteiro, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'certidao-de-estado-civil', 'certidao-estado-civil')"
              :max-count="1"
              @remove="removeFileFromArr('certidao-de-estado-civil', 'certidao-de-estado-civil')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item name="rg_conjuge" label="Documento de RG e CPF do cônjuge (caso solteiro, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'documentos-de-rg-conjuge', 'rg-conjuge')"
              :max-count="1"
              @remove="removeFileFromArr('documentos-de-rg-conjuge', 'rg-conjuge')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="w-full justify-center items-center flex py-8">
      <span class="h-[0.5px] bg-gray-300 w-full"></span>
    </div>
    <a-form-item name="dep" label="Dependentes">
      <div class="flex flex-wrap gap-4 max-[1000px]:flex-col max-[1000px]:w-full" v-for="(dependente, index) in InfoFormData.dependentes" :key="index">
        <a-form-item name="dep_nome" label="Nome" class="w-1/2 max-[1000px]:w-full">
          <a-input v-model:value="InfoFormData.dependentes[index].nome" class="w-full"/>
        </a-form-item>
        <a-form-item name="dep_nasc" label="Data de nascimento" class="w-full max-[1000px]:w-full">
          <input type="date" name="data_de_nascimento" v-model="InfoFormData.dependentes[index].data_de_nascimento" class="ant-input css-dev-only-do-not-override-19iuou">
        </a-form-item>
        <a-form-item name="dep_parent" label="Parentesco" class="w-full max-[1000px]:w-full">
          <a-input v-model:value="InfoFormData.dependentes[index].parenetesco" class="w-full"/>
        </a-form-item>
        <a-form-item name="dep_ir" label="É seu dependente no IR?" class="w-full max-[1000px]:w-full">
          <a-radio-group  v-model:value="InfoFormData.dependentes[index].dependenteIR" button-style="solid" class="flex items-center justify-center">
            <a-radio-button class="border-2" value=true >Sim</a-radio-button>
            <a-radio-button class="border-2" value=false >Não</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </div>
    </a-form-item>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item name="ceridao_nascimento" label="Certidão de nascimento de filhos menores de 14 anos (se não tiver filho, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'certidao-nascimento-filhos', 'certidao-nasciment-filho')"
              :max-count="1"
              @remove="removeFileFromArr('certidao-nascimento-filhos', 'certidao-nascimento-filhos')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item name="CPF_nascimento" label="CPF de filhos menores de 14 anos (se não tiver filhos, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'cpf-filhos', 'cpf-filho')"
              :max-count="1"
              @remove="removeFileFromArr('cpf-filhos', 'cpf-filhos')"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item name="cartao_vacina" label="Cartão de vacina de filhos menores de 6 anos e/ou comprovante de escolaridade para filhos maiores de 6 anos (se não tiver filhos, não é necessário)">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'cartao-vacina-filhos', 'cartao-vacina-filho')"
              :max-count="1"
              list-type="picture-card"
              @remove="removeFileFromArr('cartao-vacina-filhos', 'cartao-vacina-filhos')"
              class="w-1/2"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    <div class="w-full flex justify-end">
      <a-button type="primary" htmlType="submit">Enviar</a-button>
    </div>
  </a-form>
</div>
</template>

<script setup>
import { ref, reactive, defineEmits } from 'vue';
import axiosService from '@/services/axiosService';
import { message } from 'ant-design-vue';

const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
const emit = defineEmits(['handleSender'])

const gender = ref('');
const InfoFormData = reactive({
  nome_completo: '',
  data_de_nascimento: '',
  naturalidade: '',
  nacionalidade: '',
  estado_civil: '',
  nome_da_mae: '',
  nome_do_pai: '',
  endereco: '',
  cidade: '',
  bairro: '',
  estado: '',
  cep: '',
  email: '',
  telefone_residencial: '',
  telefone_celular: '',
  rg: '',
  orgao_emissor: '',
  data_de_expedicao: '',
  cpf: '',
  pis: '',
  ctps: '',
  serie: '',
  estadoctps: '',
  tituloEleitor: '',
  zona: '',
  secao: '',
  uf: '',
  pne: false,
  pne_cid: '',
  ex_funcionario: false,
  parente_funcionario: false,
  tipoBanco: '',
  agenciaBanco: '',
  contaBanco: '',
  digitoBanco: '',
  pix: '',
  escolaridade: '',
  curso_de_formacao: '',
  tamanhosUniforme: {
    altura: '',
    blusa: '',
    calca: '',
    bota: '',
  },
  dependentes: [
    {
      nome: '',
      data_de_nascimento: '',
      parenetesco: '',
      dependenteIR: '',
    },
    {
      nome: '',
      data_de_nascimento: '',
      parenetesco: '',
      dependenteIR: '',
    },
    {
      nome: '',
      data_de_nascimento: '',
      parenetesco: '',
      dependenteIR: false,
    },
    {
      nome: '',
      data_de_nascimento: '',
      parenetesco: '',
      dependenteIR: false,
    },
  ],
  files: [],
  rgFile: [],
  fotoFile: [],
  comprovanteEnderecoFile: [],
  cpfFile: [],
  pisFile: [],
  tituloEleitorFile: [],
  numeroSusFile: [],
  comprovanteEscolaridadeFile: [],
  reservistaFile: []
})

const removeFile = (label) => {
  if (!InfoFormData[label] ) {
    return false
  } 
  InfoFormData[label] = [];
};

const removeFileFromArr = (name) => {
  const fileExists = InfoFormData['files'].find(file => {
    const baseName = file.name.split('.').slice(0, -1).join('.');
    return baseName === name;
  });

  if (!fileExists) {
    return false;
  }

  const index = InfoFormData['files'].indexOf(fileExists);
  InfoFormData['files'].splice(index, 1);
}

const beforeUpload = (file, label, name) => {
  const fileExists = InfoFormData['files'].find(file => {
    const baseName = file.name.split('.').slice(0, -1).join('.');
    return baseName === name;
  });
  if (fileExists) {
    removeFileFromArr(name);
  }

  if (allowedTypes.includes(file.type)) {
    const fileExtension = file.name.split('.').pop();
    const newFileName = `${name}.${fileExtension}`;
    const renamedFile = new File([file], newFileName, { type: file.type });
    
    if (InfoFormData[label]) {
      InfoFormData[label] = renamedFile
    } else {
      InfoFormData['files'].push(renamedFile);
    }
    return false;
  }

  return alert('Formato de arquivo não suportado');
}

const handleSubmit = async () => {
    try {
    await axiosService.post('/mail/info', InfoFormData, {
      headers: {
        'Content-Length': 1000000000000,
        'Content-Type': 'multipart/form-data',
      },
    })
    message.success('Seus documentos foram enviados', 10)
    emit('handleSender');
  } catch (error) {
    message.warning('Algo deu errado, contate o suporte', 5);
    emit('handleSender');
  }

}

const formatterCelphone = (event) => {
  let inputValue = event.target.value.replace(/[^\d]/g, '');
  inputValue = inputValue.substring(0, 11);
  let formatted = '';

  if (inputValue.length > 0) {
    formatted += '(' + inputValue.substring(0, 2);
  }
  if (inputValue.length > 2) {
    formatted += ') ' + inputValue.substring(2, 7);
  }
  if (inputValue.length > 7) {
    formatted += '-' + inputValue.substring(7, 11);
  }
  InfoFormData.telefone_celular = formatted;
}

const formatterResidentialTelephone = event => {
  let inputValue = event.target.value.replace(/[^\d]/g, '');
  inputValue = inputValue.substring(0, 11);
  let formatted = '';

  if (inputValue.length > 0) {
    formatted += '(' + inputValue.substring(0, 2);
  }
  if (inputValue.length > 2) {
    formatted += ') ' + inputValue.substring(2, 7);
  }
  if (inputValue.length > 7) {
    formatted += '-' + inputValue.substring(7, 11);
  }
  InfoFormData.telefone_residencial = formatted;
}
const formatterCpfNumber = (event) => {
  const digits = event.target.value.replace(/[^\d]/g, '');
  let formatted = '';
  if (digits.length > 0) {
    formatted += digits.substring(0, 3) + '.';
  }
  if (digits.length > 3) {
    formatted += digits.substring(3, 6) + '.';
  }
  if (digits.length > 6) {
    formatted += digits.substring(6, 9) + '-';
  }
  if (digits.length > 9) {
    formatted += digits.substring(9, 11);
  }
  InfoFormData.cpf = formatted;
}

</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-radio-button-wrapper {
  margin-right: 16px;
  border-radius: 8px;
  text-align: center;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

html,
.scroll-container {
  overflow: scroll;
}

html,
.scroll-container {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion) {
  html,
  .scroll-container {
    scroll-behavior: auto;
  }
}

</style>