<script setup>
import { RouterView, useRoute } from 'vue-router';
import HeaderComponent from './components/HeaderComponent.vue';
import { computed } from 'vue';
const route = useRoute();
const path = computed(() =>route.path)

const showHeader = path.value == '/adm' | path.value == '/error' ? true : false
</script>

<template>
  <div id="app">
    <HeaderComponent v-if="showHeader"></HeaderComponent>
    <router-view />
  </div>
</template>

<style lang="scss">

@import '@/main.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
