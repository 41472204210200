<template>
  <div>
    <a-form
      ref="form"
      name="infoPersonal"
      class="w-full"
      layout="vertical"
      labelAlign="left"
      :model="filesArr"
      @submit.prevent="handleSubmit"
    >
    <a-form-item :rules="[{ required: true, message: '*' }]" label="Nome completo" class="w-full">
      <a-input v-model:value="nome_completo" />
    </a-form-item>
    <div class="flex flex-row justify-between gap-4 w-full">
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Exame admissional">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'exame_admissional')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
      <div class="w-1/2 flex flex-col text-left justify-end">
        <a-form-item label="Comprovante de abertura de conta">
          <div class="w-2/5 max-[500px]:w-full mx-auto">
            <a-upload-dragger
              :before-upload="(info) => beforeUpload(info, 'comprovante conta')"
              :max-count="1"
              :accept="allowedTypes"
              list-type="picture-card"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">PNG ou PDF</div>
              </div>
            </a-upload-dragger>
          </div>
        </a-form-item>
      </div>
    </div>
    </a-form>
    <div class="w-full flex justify-end">
      <a-button key="submit" type="primary" @click="handleSubmit">Enviar</a-button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import axiosService from '@/services/axiosService';
import { message } from 'ant-design-vue';

const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
const nome_completo = ref('')
const filesArr = ref([])
const emits = defineEmits(['handleSender'])

const beforeUpload = (file, label) => {
  if (allowedTypes.includes(file.type)) {
    const fileExtension = file.name.split('.').pop();
    const newFileName = `${label}.${fileExtension}`;
    const renamedFile = new File([file], newFileName, { type: file.type });

    filesArr.value.push(renamedFile);
    return false;
  }

  return alert('Formato de arquivo não suportado');
}

const handleSubmit = async () => {
  const formData = new FormData();
  formData.append('nome_completo', nome_completo.value);

  filesArr.value.forEach((file) => {
    formData.append('files[]', file);
  });

  try {
    await axiosService.post('/mail/docs', formData, {
      headers: {
        'content-length': 1000000000000
      }
    })
    message.success('Seus documentos foram enviados', 10)
    emits('handleSender');
  } catch (error) {
    message.warning('Algo deu errado, contate o suporte', 5);
    emits('handleSender');
  }
}

</script>

<style lang="scss" scoped>

</style>