<template>
  <div
    class="fixed inset-0 bg-gray-800 bg-opacity-65 flex items-center justify-center z-50"  
    @click.prevent="handleClose"
  >
    <form @submit.prevent="handleSubmmit" class="bg-white p-6 rounded-xl shadow-lg background flex justify-between flex-col gap-4" @click.stop>
      <p class="font-semibold font-poppins text-start text-xl">Adicionar um novo cargo</p>
      <div class="h-3/4">
        <p class="text-start font-poppins" >Mensagem</p>
        <textarea class="bg-white outline-none w-full h-full rounded-xl border-4 text-lg px-3 py-2" v-model="messageToSend" ></textarea>
      </div>
      <div class="py-4" >
        <p class="font-poppins text-start">Telefone</p>
        <div class="flex gap-4 justify-between items-center">
          <input
            type="tel"
            class="bg-white outline-none w-1/2 h-12 rounded-xl border-4 text-xl"
            pattern="\(\d{2}\) \d{5}-\d{4}"
            v-model="telNumber"
            @input="formatPhoneNumber"
            maxlength="15"
            required
          >
          <div class="flex gap-4 self-end">
            <button class="bg-[#FF8B3D] rounded-xl w-28 h-12 text-black" @click.prevent="handleClose">Cancelar</button>
            <button 
              class="bg-[#0F295E] rounded-xl w-28 h-12 text-white"
              type="submit"
            >Enviar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { defineEmits, ref, defineProps, onMounted } from 'vue';
import axiosService from '@/services/axiosService';
import { message } from 'ant-design-vue';

const emit = defineEmits(['handleMessageModalView'])
const props = defineProps(['idOpenned'])
const telNumber = ref('')
const messageToSend = ref('')

const handleClose = () => {
  emit('handleMessageModalView')
}

const handleSubmmit = async () => {
  const savedMessage = await saveMessage()
  const hash = await hashGenerateRequest()
  if (!hash || !savedMessage) {
    return false
  }
  createWppLink(removePhoneFormatting(telNumber.value), messageToSend.value, hash)
}

const createWppLink = (number, messageToSend, hash) => {
  const messageEncoded = encodeURI(messageToSend)
  emit('handleAddModalView')
  return window.open(`https://wa.me/${number}?text=${messageEncoded + '%20' + 'https://recrutamento.agetelecom.com.br/?hash=' + hash}`, '_blank')
}

const saveMessage = async () => {
  const formData = new FormData()
  formData.append('id_role', props.idOpenned)
  formData.append('message', messageToSend.value)
  try {
    const response = await axiosService.post('/messages', formData)
    return response.data
  } catch (error) {
    message.warning('Algo deu errado, entre em contato com o suporte')
    return false
  }
}

const hashGenerateRequest = async () => {
  const formData = new FormData();
  formData.append('id_role', props.idOpenned)
  try {
    const response = await axiosService.post('/hash/generate', formData)
    return response.data.hash
  } catch (error) {
    message.warning('Algo deu errado, entre em contato com o suporte')
    return false
  }
}

const removePhoneFormatting = (number) => {
  return number.replace(/\D/g, '');
}

const formatPhoneNumber = () => {
  let x = telNumber.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
  telNumber.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}

const getMessage = async () => {
  try {
    const response = await axiosService.get('/messages', {
      params: {
        id_role: props.idOpenned
      }
    })
    console.log(response.data.data)
    return response.data.data.message != null ? messageToSend.value = response.data.data.message  : false
  } catch (error) {
    message.warning('Algo deu errado, entre em contato com o suporte')
    return false
  }
}

onMounted(getMessage)

</script>

<style lang="scss" scoped>

.background {
  background-image: url('../../../assets/baseModal.svg');
  background-size: cover;
  height: 60vh;
  width: 50vw;
}

</style>