<template>
  <div
    @wheel="handleScroll"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    id="formSection"
    class="h-dvh relative"
  >
    <p class="absolute title flex flex-col text-4xl max-[500px]:text-xl max-[500px]:my-2 w-full max-[1000px]:items-center min-[1000px]:pl-40 font-bold font-poppins items-start my-4">Documentos<span class="text-age-orange">obrigatórios</span></p>
    <div class="flex flex-row justify-evenly w-full p-4 h-full items-center max-[1000px]:flex-col max-[1000px]:justify-center max-[1000px]:gap-16">
      <!-- <div class="inset-0 card1 z-10 shadow-2xl transform rounded-2xl relative h-4/6 w-1/5 max-[1000px]:w-5/6 max-[1000px]:h-1/6 flex flex-col justify-end items-center" id="docsForm">
        <img class="absolute -top-10 h-3/5 max-[1000px]:-top-12" :src="person1">
        <p class="text-4xl font-poppins max-[1000px]:text-xl text-[#FA6400] font-extrabold">Formulário 1</p>
        <p class="mt-2 max-[1000px]:mt-0 text-sm max-[1000px]:text-xs">Documentos obrigatórios</p>
        <button @click="showDocsModal" class="bg-gradient-to-r from-[#F9B203] to-[#FA6400] items-center w-4/5 rounded-lg m-4 max-[1000px]:m-2 p-1 px-4 text-nowrap text-white mt-10">Preencher Formulário</button>
      </div> -->
      <div class="inset-0 card1 z-10 shadow-2xl transform rounded-2xl relative h-4/6 w-1/5 max-[1000px]:w-5/6 max-[1000px]:h-1/6 flex flex-col justify-end items-center" id="infoForm">
        <img class="absolute -top-10 h-3/5 max-[1000px]:-top-12" :src="person2">
        <p class="text-3xl font-poppins max-[1000px]:text-xl text-[#FA6400] font-extrabold">Dados pessoais</p>
        <p class="mt-2 max-[1000px]:mt-0 text-sm max-[1000px]:text-xs">Dados pessoais e documentos</p>
        <button @click="showInfoModal" class="bg-gradient-to-r from-[#F9B203] to-[#FA6400] items-center w-4/5 rounded-lg m-4 max-[1000px]:m-2 p-1 px-4 text-nowrap text-white mt-10">Preencher Formulário</button>
      </div>
      <div class="inset-0 card2 z-10 shadow-2xl transform rounded-2xl relative h-4/6 w-1/5 max-[1000px]:w-5/6 max-[1000px]:h-1/6 flex flex-col justify-end items-center" id="otherForm">
        <img class="absolute -top-10 h-3/5 max-[1000px]:-top-12" :src="person3">
        <p class="text-3xl font-poppins max-[1000px]:text-xl text-[#FA6400] font-extrabold">Documentos adicionais</p>
        <p class="mt-2 max-[1000px]:mt-0 text-sm max-[1000px]:text-xs px-2">Exame adimissinal e comprovante de abertura de conta</p>
        <button @click="showOtherDocsModal" class="bg-gradient-to-r from-[#F9B203] to-[#FA6400] items-center w-4/5 rounded-lg m-4 max-[1000px]:m-2 p-1 px-4 text-nowrap text-white mt-10">Preencher Formulário</button>
      </div>
    </div>
    <a-modal
      v-model:open="openOtherDocsModal"
      class="w-full max-[1000px]:w-11/12"
      :width="modalWidth"
    >
      <template #footer>
      </template>
      <DocumentsForm @handleSender="handleSenderDocs"></DocumentsForm>
    </a-modal>
    <a-modal
    v-model:open="openInfoForm" 
    class="w-full max-[1000px]:w-11/12"
    :width="modalWidth"
    >
      <template #footer>
      </template>
      <DocsForm @handleSender="handleSenderInfo"></DocsForm>
    </a-modal>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script setup>
// import person1 from '@/assets/pessoa1.svg'
import person2 from '@/assets/pessoa2.svg'
import person3 from '@/assets/pessoa3.svg'
import FooterComponent from '@/components/FooterComponent.vue'
import router from '@/router'
import DocsForm from '../forms/DocsForm.vue'
import { ref, onMounted, onUnmounted } from 'vue'
import DocumentsForm from '../forms/DocumentsForm.vue'

const openInfoForm = ref(false);
const openOtherDocsModal = ref(false);
const modalWidth = ref('50%');

const updateModalWidth = () => {
  if (window.innerWidth > 1000) {
    return modalWidth.value = '65%';
  } 
  return modalWidth.value = '92%';
}

const showInfoModal = () => {
  openInfoForm.value = true;
};

const showOtherDocsModal = () => {
  openOtherDocsModal.value = true;
};

const handleScroll = (event) => {
  setTimeout(() => {
    if (event.deltaY < 0) {
      router.push('/video')
    }
  })
}

const handleSenderInfo = () => {
  openInfoForm.value = false;
};

const handleSenderDocs = () => {
  openOtherDocsModal.value = false;
};

let startY = 0;

const handleTouchStart = (event) => {
  startY = event.touches[0].clientY;
}

const handleTouchMove = (event) => {
  event.preventDefault();      
  const currentY = event.touches[0].clientY;
  const deltaY = currentY - startY;

  setTimeout(() => {
    if (deltaY > 0) {
      router.push('/descricao')
    }
  })
};

const validateHash = () => {
  const hash = sessionStorage.getItem('roleHash');
  return !hash ? router.push('/error') : true;
}

onMounted(() => {
  validateHash();
  updateModalWidth();
  window.addEventListener('resize', updateModalWidth)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateModalWidth)
})

</script>

<style lang="scss" scoped>
.title {
  opacity: 0;
  animation: titleAnimation 2s ease-out forwards
}

.card1 {
  opacity: 0;
  animation: cardAnimation 1.5s ease-out forwards;
}

.card2 {
  opacity: 0;
  animation: cardAnimation 1.5s ease-out forwards;
  animation-delay: 1.5s;
}

.card3 {
  opacity: 0;
  animation: cardAnimation 1.5s ease-out forwards;
  animation-delay: 3s;
}

@keyframes titleAnimation {
  0% {
    opacity: 0;
    left: -100%;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes cardAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  } 
}

</style>