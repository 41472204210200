<template>
  <div
    @wheel="handleScroll"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"  
    class="flex justify-center h-dvh"
  >
    <div class="card relative flex flex-row bg-gradient-to-tr from-[#FA6400] to-[#F7B500] rounded-xl w-4/5 py-8 items-center justify-start m-auto h-4/6 max-[500px]:flex-col max-[500px]:h-3/6 max-[500px]:">
      <div class="w-2/6 h-full flex justify-center items-center max-[500px]:w-full  max-[500px]:h-1/2">
        <p class="text-2xl font-poppins text-white font-bold max-[500px]:text-lg">
          Conheça mais sobre a Age
        </p>
      </div>
      <div class="video absolute w-4/6 h-full p-3 max-[500px]:w-full max-[500px]:h-2/5">
        <vue-plyr>
          <div data-plyr-provider="youtube" data-plyr-embed-id="1WYsiRNFoMI"></div>
        </vue-plyr>
      </div>
    </div>
    <a href="#" @click.prevent="scrollToDiv('formularios')">
      <img
        :src=scroll
        class="hover:animate-bounce w-10 absolute right-8 bottom-8"
      >
    </a>
  </div>
</template>

<script setup>
import router from '@/router';
import scroll from '@/assets/Scroll.svg';
import { onMounted } from 'vue';

const handleScroll = (event) => {
  setTimeout(() => {
    if (event.deltaY > 0) {
      router.push('/formularios')
    } else {
      router.push('/descricao')
    }
  },500)
}

let startY = 0;

const handleTouchStart = (event) => {
  startY = event.touches[0].clientY;
}

const handleTouchMove = (event) => {
  event.preventDefault();      
  const currentY = event.touches[0].clientY;
  const deltaY = currentY - startY;

  setTimeout(() => {
    if (deltaY < 0) {
      router.push('/formularios')
    } else {
      router.push('/descricao')
    }
  },500)
};

const scrollToDiv = (selector) => {
  router.push(`/${selector}`)
}

const validateHash = () => {
  const hash = sessionStorage.getItem('roleHash');
  return !hash ? router.push('/error') : true;
}

onMounted(validateHash);
</script>

<style lang="scss" scoped>
.card {
  opacity: 0;
  animation: cardAnimation 2s ease-out forwards;
}

@media screen and (max-width: 600px) {
  .video {
    top: 50%;
    opacity: 0;
    animation: videoAnimation 2s ease-out forwards;
  }
}

.video {
  opacity: 0;
  animation: videoAnimation 2s ease-out forwards;
}

@keyframes videoAnimation {
  0%{
    opacity: 0;
    right: -100%;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
    right: 0%;
  }
}

@keyframes cardAnimation {
  0%{
    opacity: 0;
    right: -100%;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
    right: 0%;
  }
}
</style>